<template>
<div v-if="getAllowFullSite" class="main content-page about">

    <div class="content-sidebar vertical-image"></div>

    <div class="content-page-inner">
        <h1>Privacy Policies</h1>
        <h2>GAP may collect your personal information</h2>
        <p>As you interact with GAP's website, we may collect personal information like your name, email address, mailing address, phone number, credit card information, etc. This happens when you make a donation, register for an event, subscribe to our newsletters, or fill out a contact request. We maintain a secure record of your contact and donation information.</p>

        <h2>GAP does not share your information</h2>
        <p>We do not share your information. We will only use your information to help with your interaction with our website, staff and volunteers.  Some ways we use your information include...</p>
        <p style="margin-left: 2em;">
            To process your online donation<br>
            To identify GAP activities and projects that might fit your requests<br>
            To send you information by email, standard mail, or phone as needed in order fulfill your request
        </p>

        <h2>Your personal information is secure</h2>
        <p>We do our best to provide you with a safe online experience. Our data center adheres to strict physical and electronic safeguards to protect your security and privacy. All interactions with our website use Secure Sockets Layer (SSL) technology to protect your information as it is transmitted to us.<p>

        <h2>GAP will never ask for personal information by email</h2>
        <p>If you receive an email that looks like it is from GAP asking you for your personal information, do not respond. We will never request your password or user name through email.</p>

        <h2>GAP's website uses cookies</h2>
        <p>Our website uses "cookies," which are small files that are stored in your browser. Cookies allow your browser to better interact with parts of our website.</p>

        <h2>Links to other websites</h2>
        <p>GAP's website may contain links to other sites, which may have different privacy policies. We are not responsible for the content or practices of linked sites.</p>

        <h2>Contact GAP</h2>
        <p>For questions about our privacy policy, to make choices about receiving communications, to update your personal information, you can contact GAP by regular mail or email...</p>

        <p style="margin-left: 2em;">
            Great Awakening Project<br>
            PO Box 43887<br>
            Birmingham, AL 35243
        </p>
        <p style="margin-left: 2em;">
            Email: info@gapmovement.com
        </p>

    </div>

</div>
</template>

<script>
export default {
    name: 'privacy-policies',
    data() {
        return {
        }
    },
    created() {
        if (!this.getAllowFullSite) {
            this.$router.push('/coming-soon')
        }
        window.scrollTo(0, 0);
    }
}
</script>

<style lang="scss" scoped>

.vertical-image {
    overflow: hidden;
    background-image: url("~@/assets/about1.jpg");
    background-size: cover;
    background-position: center bottom;
}
</style>
